import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Context from "../components/Context"
import SEO from "../components/Seo"
import Cover from "../components/Cover"

import Anchor from "../modules/Anchor"
import ScrollDown from "../modules/ScrollDown"
import ScrollUp from "../modules/ScrollUp"
import Card from "../modules/Card"

import { slugify } from "../functions/slugify"

export default class Home extends Component {
  state = {
    sections: {
      cover: "couverture",
      events: "voyages",
      description: "biographie",
    },
  }

  componentDidMount() {
    if (this.context.data.sections !== this.state.sections) {
      this.context.set({ sections: this.state.sections })
    }

    const { data } = this.props
    // current page
    const blog = data.blog

    const header = {
      currentName: blog.title,
      currentType: "blog",
      buttons: this.state.sections,
      counts: {
        events: blog.events ? blog.events.length : null,
      },
    }
    if (this.context.data.header !== header) {
      this.context.set({ header })
    }

    const { mapdata } = blog
    if (this.context.data.mapdata !== mapdata) {
      this.context.set({ mapdata: { bbox: mapdata.bbox } })
    }
  }

  // componentDidUpdate() {
  //   console.log("componentDidUpdate() ==> HOME")
  // }

  render() {
    const { sections } = this.state
    const { data } = this.props

    // current page
    const blog = data.blog

    return (
      <Fragment>
        {/* SEO */}

        <SEO
          pageTitle={`${blog.title} : ${blog.name}`}
          pageUrl={this.context.data.metadata.siteUrl + "/"}
        />

        {/* END : SEO */}

        {/* COVER */}

        <Anchor id={sections.cover} />

        <Cover current={blog} scroll={sections.events} />

        <Anchor id={"parallax-menu"} />

        {/* END : COVER */}

        {/* EVENTS */}

        <Anchor id={sections.events} />

        <div className="uk-background-default">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.cover)} />
            </div>

            <div className="uk-container uk-container-medium uk-animation-fade">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.events}
              </h3>

              <div
                className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-1@m uk-child-width-1-2@l uk-child-width-1-3@xl uk-text-center uk-flex-center"
                data-uk-grid
              >
                {blog.events &&
                  blog.events.map(event => {
                    return (
                      <div key={event.contentful_id}>
                        <Card
                          link={"/" + event.slug}
                          fluid={event.cover.fluid}
                          title={event.title}
                          label={event.name}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.description)} />
            </div>
          </div>
        </div>
        {/* END : EVENTS */}

        {/* DESCRIPTION */}

        <Anchor id={sections.description} />

        <div className="uk-background-muted">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.events)} />
            </div>

            <div className="uk-container uk-container-medium uk-animation-fade">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.description}
              </h3>

              <div className="uk-text-justify uk-text-lead">
                <Img
                  fluid={blog.avatar.fluid}
                  className="uk-align-left uk-width-2-3 uk-width-1-3@s uk-margin uk-border-circle"
                />
                {blog.description && (
                  <em>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blog.description.childMarkdownRemark.html,
                      }}
                    />
                  </em>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* END : DESCRIPTION */}
      </Fragment>
    )
  }
}

Home.contextType = Context

export const query = graphql`
  query {
    blog: contentfulBlog {
      name
      title
      contentful_id
      cover {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      avatar {
        fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid
        }
      }
      mapdata
      events {
        contentful_id
        name
        title
        slug
        cover {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid
          }
        }
        parts {
          contentful_id
          title
          slug
          days {
            contentful_id
            title
            slug
            trace {
              content
            }
          }
        }
      }
    }
  }
`
